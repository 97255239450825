@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700&display=swap');
:root {
  --red: #CB1100;
  --lightgray:#999;
  --white: #ffffff;
  --darkgray: #222222;
  --smokegray: #F5F5F5;
  --lightblue:#F2F5FA;
}
body {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    padding: 0;
    font-size: 14px;
    line-height: 20px;
    color: #262626;
}
a{text-decoration: none;}
a:hover{text-decoration: none;}

.f10{font-size: 10px !important;}
.f12{font-size: 12px !important;}
.f13{font-size: 13px !important;}
.f14{font-size: 14px !important;}
.f15{font-size: 15px !important;}
.f16{font-size: 16px !important;}
.f17{font-size: 17px !important; line-height: 24px;}
.f18{font-size: 18px !important; line-height: 26px;}
.f19{font-size: 19px !important;}
.f20{font-size: 20px !important;}
.f21{font-size: 21px !important;}
.f22{font-size: 22px !important;}
.f23{font-size: 23px !important;}
.f24{font-size: 24px !important;}
.f26{font-size: 26px !important;}
.f28{font-size: 28px !important;}

.txtlgeay{color: var(--lightgray);}
.txtdgray{color: var(--darkgray) !important;}
.txtred{color: var(--red) !important;}
.linkblue{color: #1976d2;}

.bgred{background-color: var(--red) !important;}
.bgsgray{background-color: var(--smokegray);}
.bglblue{background-color: var(--lightblue);}
.bgdgray{background-color: var(--darkgray);}

.redborder{border:solid 1px var(--red) !important}



.tabred .MuiTab-textColorPrimary.Mui-selected{color: var(--red) !important;}
.remove-tringle.dropdown-toggle::after{border: none !important; margin-left:0px} 
.round-edit{background-color:rgba(0, 0, 0, 0.04); border-radius: 50%; cursor: pointer;}

.innerpagebg{background-color: #FAFAFA;}
.site-footer {
  position: relative;
  color: #b3b3b3;
}
.btn-out{background-color: var(--red); top: -15px !important; padding: 5px; border: #ffffff 1px solid; z-index: 9999 !important; }
.btn-out:hover{cursor: pointer;}

/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
.w-sm-100 {
width:100%!important;
}
.w-sm-75 {
width:75%!important;
}
.w-sm-50 {
width:50%!important;
}
.w-sm-25 {
width:25%!important;
}
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
.w-md-100 {
width:100%!important;
}
.w-md-75 {
width:75%!important;
}
.w-md-50 {
width:50%!important;
}
.w-md-25 {
width:25%!important;
}
}

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
.w-lg-100 {
width:100%!important;
}
.w-lg-75 {
width:75%!important;
}
.w-lg-50 {
width:50%!important;
}
.w-lg-25 {
width:25%!important;
}
}

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
.w-xl-100 {
width:100%!important;
}
.w-xl-75 {
width:75%!important;
}
.w-xl-50 {
width:50%!important;
}
.w-xl-25 {
width:25%!important;
}
}

@media only screen and (max-width: 512px) {
  .f14sm{font-size: 14px !important;}
}