:root {
    --red: #CB1100;
    --lightgray:#999;
    --white: #ffffff;
    --darkgray: #222222;
}
.site--desktop-header--spaceship-one .header{
    display:grid;
    grid-template-columns:-webkit-calc(100%/2 - 1350px/2) 1fr auto 1fr -webkit-calc(100%/2 - 1350px/2);
    grid-template-columns:-moz-calc(100%/2 - 1350px/2) 1fr auto 1fr -moz-calc(100%/2 - 1350px/2);
    grid-template-columns:calc(100% / 2 - 1350px / 2) 1fr auto 1fr calc(100% / 2 - 1350px / 2);
    grid-template-rows:30px auto
}
@media(min-width:1200px)and (max-width:1399.98px){
    .site--desktop-header--spaceship-one .header{
        grid-template-columns:-webkit-calc(100%/2 - 1110px/2) 1fr auto 1fr -webkit-calc(100%/2 - 1110px/2);
        grid-template-columns:-moz-calc(100%/2 - 1110px/2) 1fr auto 1fr -moz-calc(100%/2 - 1110px/2);
        grid-template-columns:calc(100% / 2 - 1110px / 2) 1fr auto 1fr calc(100% / 2 - 1110px / 2)
    }
}
.site--desktop-header--spaceship-one .header__megamenu-area{
    grid-column:2/5;
    grid-row:1
}
.site--desktop-header--spaceship-one .header__topbar-start-bg{
    grid-column:1/3;
    grid-row:1
}
.site--desktop-header--spaceship-one .header__topbar-end-bg{
    grid-column:4/6;
    grid-row:1;
    overflow: hidden;
}
.site--desktop-header--spaceship-one .header__topbar-start{
    grid-column:2;
    grid-row:1
}
.site--desktop-header--spaceship-one .header__topbar-end{
    grid-column:4;
    grid-row:1
}
.site--desktop-header--spaceship-one .header__navbar{
    grid-column:2;
    grid-row:2
}
.site--desktop-header--spaceship-one .header__logo{
    grid-column:3;
    grid-row:1/3
}
.site--desktop-header--spaceship-one .header__search{
    grid-column:3/3;
    grid-row:1/3
}
.site--desktop-header--spaceship-one .header__indicators{
    grid-column:4;
    grid-row:2
}
.site--desktop-header--spaceship-one .header{
    position:relative;
    z-index:10
}
.site--desktop-header--spaceship-one .header {
    position: relative; z-index: 10; background: var(--white); -webkit-box-shadow: 0 1px 3px rgb(0 0 0 / 9%); box-shadow: 0 1px 3px rgb(0 0 0 / 9%);
}
.site--desktop-header--spaceship-one .header__topbar-start {
    grid-column: 2;  grid-row: 1; z-index: 1;padding-right: 20px;
}
.site--desktop-header--spaceship-one .header__topbar-end-bg::before {
    background: var(--darkgray);
}
.site--desktop-header--spaceship-one .topbar--spaceship-start {
    background:var(--red);
}
.site--desktop-header--spaceship-one .topbar {
    display: flex; height: 100%; font-size: 16px; line-height: 1;
}
.site--desktop-header--spaceship-one .header__topbar-start-bg::before {
    background: var(--red); border-bottom-right-radius: 2.5px; -webkit-transform: skewX(-30deg); -moz-transform: skewX(-30deg); transform: skewX(-30deg); -webkit-transform-origin: top right;
    -moz-transform-origin: top right; transform-origin: top right; display: block; content: "";  width: 100%; height: 100%;
}
.site--desktop-header--spaceship-one .header__topbar-end-bg::before{
    border-bottom-left-radius: 2.5px;  -webkit-transform: skewX(30deg); -moz-transform: skewX(30deg); transform: skewX(30deg); -webkit-transform-origin: top left; -moz-transform-origin: top left;
    transform-origin: top left; display: block; content: ""; width: 100%; height: 100%;
}
.site--desktop-header--spaceship-one .header__topbar-end {
    grid-column: 4; grid-row: 1;
}
.site--desktop-header--spaceship-one .header__topbar-end{
    padding-left: 20px; z-index: 1;
}
.site--desktop-header--spaceship-one .topbar--spaceship-end {
    background: var(--darkgray); justify-content: flex-end;  display: flex;  height: 100%;font-size: 14px; line-height: 1;
}
.site--desktop-header--spaceship-one .header__search {
    position: absolute; align-self: flex-end; justify-self: center; bottom: -30px; width: 480px;
}
.site--desktop-header--spaceship-one .search__body {
    position: relative; display: flex; z-index: 1; height: 50px;
}
.site--desktop-header--spaceship-one .search__shadow {
    position: absolute;  bottom: 100%; left: 10px;height: 10px; width: 100%; overflow: hidden;
}
.site--desktop-header--spaceship-one .search__shadow:before {
    display: block;
    content: "";
    width: 100%;
    height: 60px;
    position: relative;
    top: 100%;
    -webkit-box-shadow: 0 -1px 4px rgb(0 0 0 / 12%), 0 -1px rgb(0 0 0 / 2%);
    box-shadow: 0 -1px 4px rgb(0 0 0 / 12%), 0 -1px rgb(0 0 0 / 2%);
    border-radius: 120px/10px;
}
.site--desktop-header--spaceship-one .search__input {
    width: 0;
    flex-shrink: 1;
    -moz-box-flex: 1;
    flex-grow: 1;
    background: transparent;
    border: none;
    padding: 0 12px;
    text-align: center;
    font-size: 15px;
    margin: 6px 0;
    z-index: 1;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: #262626;
}
.site--desktop-header--spaceship-one .search__decor {
    position: absolute;
    display: -moz-box;
    display: flex;
    bottom: -3px;
    height: 34px;
    width: -webkit-calc(100% + 20px);
    width: -moz-calc(100% + 20px);
    width: calc(100% + 20px);
    z-index: -1;
}
.site--desktop-header--spaceship-one .search__decor {
    left: -10px;
}
.site--desktop-header--spaceship-one .search__decor-end, .site--desktop-header--spaceship-one .search__decor-start {
    overflow: hidden;
    height: 100%;
    -moz-box-flex: 1;
    flex-grow: 1;
    padding: 0 10px;
}
.site--desktop-header--spaceship-one .search__decor-start:before {
    border-bottom-left-radius: 2px;
    margin-right: -20px;
    -webkit-transform: skewX(30deg);
    -moz-transform: skewX(30deg);
    transform: skewX(30deg);
    -webkit-transform-origin: left top;
    -moz-transform-origin: left top;
    transform-origin: left top;
    background-color:var(--white);
    -webkit-box-shadow: 0 1px 5px rgb(0 0 0 / 9%);
    box-shadow: 0 1px 5px rgb(0 0 0 / 9%);
    content: "";
    display: block;
    height: 24px;
}
.site--desktop-header--spaceship-one .search__decor-end:before {
    border-bottom-right-radius: 2px;
    margin-left: -20px;
    -webkit-transform: skewX(-30deg);
    -moz-transform: skewX(-30deg);
    transform: skewX(-30deg);
    -webkit-transform-origin: right top;
    -moz-transform-origin: right top;
    transform-origin: right top;
    background-color:var(--white);
    -webkit-box-shadow: 0 1px 5px rgb(0 0 0 / 9%);
    box-shadow: 0 1px 5px rgb(0 0 0 / 9%);
    content: "";
    display: block;
    height: 24px;
}
.site--desktop-header--spaceship-one nav.navbar{padding: 0px;}
.site--desktop-header--spaceship-one .search__button {
   z-index: 1;
}
.site--desktop-header--spaceship-one .search__button:before {
    position: absolute;
    display: block;
    content: "";
    width: 3px;
    top: 0;
    bottom: 0;
    background: #ebebeb;
    z-index: -1;
    border-radius: 1.5px;
    left: -8px;
    -webkit-transform: skewX(-30deg);
    -moz-transform: skewX(-30deg);
    transform: skewX(-30deg);
}
.site--desktop-header--spaceship-one .search__button--start:before {
    left: auto;
    right: -8px;
    -webkit-transform: skewX(30deg);
    -moz-transform: skewX(30deg);
    transform: skewX(30deg);
}
.css-1480iag-MuiInputBase-root-MuiInput-root:after {
    border-bottom: none !important
}
.mobile-left-nav .navbar-nav .nav-link{
    padding: 18px;
    padding-left: 20px;
    padding-right: 33px;    font-size: 15px;
    line-height: 22px; color: #262626;  font-weight: 500; border-bottom:solid 1px #ededed
}
.site--desktop-header--spaceship-one .header__indicators {
        align-self: center;
        display: -moz-box;
        display: flex;
        -moz-box-pack: end;
        justify-content: flex-end;
        padding: 10px 0;
}
.site--desktop-header--spaceship-one .search__dropdown {
    color: #262626;
    background-color: #fff;
    -webkit-box-shadow: 0 2px 20px rgb(0 0 0 / 20%);
    box-shadow: 0 2px 20px rgb(0 0 0 / 20%);
    position: absolute;
    top: 100%;
    border-radius: 1.5px;
    opacity: 0;
    -webkit-transform: translateY(26px);
    -moz-transform: translateY(26px);
    transform: translateY(26px);
    visibility: hidden;
    -webkit-transition: opacity .25s cubic-bezier(.645,.045,.355,1),visibility 0s .25s,-webkit-transform .25s cubic-bezier(.645,.045,.355,1);
    transition: opacity .25s cubic-bezier(.645,.045,.355,1),visibility 0s .25s,-webkit-transform .25s cubic-bezier(.645,.045,.355,1);
    -moz-transition: opacity .25s cubic-bezier(.645,.045,.355,1),transform .25s cubic-bezier(.645,.045,.355,1),visibility 0s .25s,-moz-transform .25s cubic-bezier(.645,.045,.355,1);
    transition: opacity .25s cubic-bezier(.645,.045,.355,1),transform .25s cubic-bezier(.645,.045,.355,1),visibility 0s .25s;
    transition: opacity .25s cubic-bezier(.645,.045,.355,1),transform .25s cubic-bezier(.645,.045,.355,1),visibility 0s .25s,-webkit-transform .25s cubic-bezier(.645,.045,.355,1),-moz-transform .25s cubic-bezier(.645,.045,.355,1);
}
.site--desktop-header--spaceship-one .search__dropdown--open {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    transform: translateY(0);
    visibility: visible;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    transition-delay: 0s;
}
.site--desktop-header--spaceship-one .search__dropdown {
    top: -webkit-calc(100% + 1px);
    top: -moz-calc(100% + 1px);
    top: calc(100% + 1px);
}
.site--desktop-header--spaceship-one .suggestions {
    padding: 14px 0;
}
.site--desktop-header--spaceship-one .search__dropdown, .site--desktop-header--spaceship-one .search__dropdown {
    left: 14px;
    right: 14px;
}

.product-card {
    -webkit-box-shadow: 0 1px 3px rgb(0 0 0 / 9%);
    box-shadow: 0 1px 3px rgb(0 0 0 / 9%);
    flex-direction: column;
    border: solid 1px #fff;
}
.product-card:hover{
    -webkit-box-shadow: 0 4px 9px rgb(0 0 0 / 9%);
    box-shadow: 0 4px 9px rgb(0 0 0 / 9%);
    border: solid 1px #dee2e6;
}
.product-card:hover .HoverBgRed{
    background-color: var(--red);
    color: #fff !important;
}
.product-card--layout--grid .product-card__actions-list {
    right: 6px;
    position: absolute;
    z-index: 1;
}
.product-card--layout--grid .product-card__image {
    display: block;
    position: relative;
}
.image--type--product .image__tag {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: scale-down;
}
.image--type--category .image__body, .image--type--product .image__body {
    display: block;
    position: relative;
    width: 100%;
}
.image--type--category .image__body, .image--type--product .image__body {
    padding-bottom: 100%;
}
.product-card--layout--grid .product-card__meta {
    padding: 6px 16px;
    font-size: 12px;
    line-height: 1;
    color: #999;
}
.product-card--layout--grid .product-card__desc {
    padding: 6px 16px;
    font-size: 12px;
    line-height: 16px;
    color: #999;
}

.product-card--layout--grid .product-card__name {
    padding: 0 16px;
    line-height: 1.1875;
}
.product-card .product-card__name a {
    color: inherit;
}
.product-card--layout--grid .product-card__footer {
    padding: 11px 16px;
    -moz-box-align: center;
    align-items: center;
    display: -moz-box;
    display: flex;
    -moz-box-ordinal-group: 3;
    order: 2;
}
.product-card--layout--grid .product-card__prices {
    -moz-box-flex: 1;
    flex-grow: 1;
    font-size: 18px;
    display: -moz-box;
    display: flex;
    -moz-box-align: end;
    align-items: flex-end;
    flex-wrap: wrap;
}
.product-card .product-card__prices {
    font-weight: 500;
    letter-spacing: -.04em;
    line-height: 1;
    color: #262626;
}
.HoverBgRed:hover{
    background-color: var(--darkgray) !important;
    color: #fff !important;
}

.bg-border-mid:after {
    height: 1px;
    content: "";
    top: 50%;
    left: 0;
    width: 100%;
    background: var(--lightgray);
    position: absolute;
}
.tiltbg:after {
    position: absolute;
    display: block;
    content: "";
    width: -webkit-calc(100% - 8.37131px);
    width: -moz-calc(100% - 8.37131px);
    width: calc(100% - 8.37131px);
    height: 100%;
    top: 0;
    background: transparent;
    z-index: -1;
    pointer-events: auto;
    -webkit-transition: background .1s;
    -moz-transition: background .1s;
    transition: background .1s;
    background: #333;
}

.tiltbg:after {
    left: 0;
    -webkit-transform: skewX(-20deg);
    -moz-transform: skewX(-20deg);
    transform: skewX(-20deg);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    transform-origin: left bottom;
    border-top-left-radius: 2px;
    border-top-right-radius: 2.5px;
    border-bottom-left-radius: 2.5px;
    border-bottom-right-radius: 2px;
}
.tiltbgred:after {
    position: absolute;
    display: block;
    content: "";
    width: -webkit-calc(100% - 8.37131px);
    width: -moz-calc(100% - 8.37131px);
    width: calc(100% - 8.37131px);
    height: 100%;
    top: 0;
    background: transparent;
    z-index: -1;
    pointer-events: auto;
    -webkit-transition: background .1s;
    -moz-transition: background .1s;
    transition: background .1s;
    background: var(--red);
}

.tiltbgred:after {
    left: 0;
    -webkit-transform: skewX(-20deg);
    -moz-transform: skewX(-20deg);
    transform: skewX(-20deg);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    transform-origin: left bottom;
    border-top-left-radius: 2px;
    border-top-right-radius: 2.5px;
    border-bottom-left-radius: 2.5px;
    border-bottom-right-radius: 2px;
}


tiltbgred
.addtocartbtn:hover svg{fill: #fff !important;}
.navbar-light .navbar-nav .nav-link{color: var(--darkgray);}


.megamenu {
    position: static;
  }
.header__navbar .dropdown-menu.show{width: 100%;}
.megamenu .dropdown-menu {
    background: none;
    border: none;
    width: 100%;
    left:0px;
}
  .megaimaga
  {
    background: center center url("https://www.hondaindiapower.com/uploads/products/7/EU30is.jpg")no-repeat; background-size: cover;
  }


.owl-theme .owl-nav {
    text-align: right !important;
}
.owl-theme .owl-nav [class*='owl-']{
    background: var(--darkgray) !important;
    color: #fff !important;
    width: 40px;
    left: 0;
    transform: skewX(-20deg);
    transform-origin: left bottom;
    font-size: 22px;
}
.owl-theme .owl-nav span{
    font-size: 22px;
}
.aboutbg{background: center center url("https://demo.wipl.co.in/honda/aboutus-bg.png")no-repeat; background-size: cover; background-color: #fff !important;}
.dealerbg{
    background: linear-gradient(rgba(0, 0, 0, 0.70), rgba(0, 0, 0, 0.40)), transparent url(https://honda.wipl.co.in/images/honda_img_2.jpg) 50% 50%/cover no-repeat scroll;
}
.productenq{ background: linear-gradient(rgba(0, 0, 0, 0.70), rgba(0, 0, 0, 0.40)), transparent url(https://honda.wipl.co.in/images/honda_img_1.jpg) 50% 50%/cover no-repeat scroll;}

.post-card {
    background-color: #fff;
    -webkit-box-shadow: 0 1px 3px rgb(0 0 0 / 9%);
    box-shadow: 0 1px 3px rgb(0 0 0 / 9%);
}
.post-card .post-card__image img {
    max-width: 100%;
    height: auto;
}
.post-card .post-card__content {
    display: -moz-box;
    display: flex;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    flex-direction: column;
    padding: 1.125rem 1.25rem 1rem;
    position: relative;
}
.post-card .post-card__category {
    left: 0;
    height: 22px;
    padding: 0.75rem 1.25rem 0;
    font-size: 13px;
    position: absolute;
    bottom: -webkit-calc(100% - 1px);
    bottom: -moz-calc(100% - 1px);
    bottom: calc(100% - 1px);
    font-weight: 500;
    z-index: 0;
}
.post-card .post-card__category:before {
    left: 0;
    border-top-right-radius: 2px;
    -webkit-transform: skewX(30deg);
    -moz-transform: skewX(30deg);
    transform: skewX(30deg);
    -webkit-transform-origin: left top;
    -moz-transform-origin: left top;
    transform-origin: left top;
    width: 100%;
    position: absolute;
    display: block;
    content: "";
    top: 0;
    background: #fff;
    z-index: -1;
    height: 100%;

}
.post-card .post-card__category a {
    display: block;
}
.post-card .post-card__category:after {
    left: 0;
}
.post-card .post-card__category:after {
    width: 50px;
}
 .post-card .post-card__category:after{
    position: absolute;
    display: block;
    content: "";
    top: 0;
    background: #fff;
    z-index: -1;
    height: 100%;
}
.post-card .post-card__title h2 {
    font-size: 15px;
    line-height: 20px;
    overflow: hidden;
    max-height: 40px;
    margin: 0;
}
.decor {
    display: block;
}
.decor--type--bottom {
    width: 100%;
    position: relative;
    pointer-events: none;
    overflow: hidden;
    height: 31px;
}
.site-footer__decor {
    position: absolute;
    bottom: -webkit-calc(100% - 1px);
    bottom: -moz-calc(100% - 1px);
    bottom: calc(100% - 1px);
}
.decor--type--bottom .decor__start {
    position: absolute;
    width: -webkit-calc((100% - 1000px - 160px)/2);
    width: -moz-calc((100% - 1000px - 160px)/2);
    width: calc((100% - 1000px - 160px) / 2);
    bottom: 0;
    left: 0;
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    transform-origin: right bottom;
    height: 21px;
    border-top-right-radius: 2px;
    background: #333;
    -webkit-transform: skewX(30deg);
    -moz-transform: skewX(30deg);
    transform: skewX(30deg);
}
.decor--type--bottom .decor__end {
    position: absolute;
    width: -webkit-calc((100% - 1000px - 160px)/2);
    width: -moz-calc((100% - 1000px - 160px)/2);
    width: calc((100% - 1000px - 160px) / 2);
    bottom: 0;
    right: 0;
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    transform-origin: left bottom;
    height: 21px;
    border-top-right-radius: 2px;
    background: #333;
    -webkit-transform: skewX(-30deg);
    -moz-transform: skewX(-30deg);
    transform: skewX(-30deg);
}
.flinkmanu{
    list-style: none;
    margin:0px;
    padding: 0px;
}
.flinkmanu li{
   margin-bottom: 6px;
}
.flinkmanu li a{
    color:#b3b3b3;
    display:block;
}
.flinkmanu li a:hover{
    color:#fff
}

/* compare */
.compare-table__column--header {
    width: 16%;
    background: #f7f7f7 !important;
    font-weight: 500;
    min-width: 140px;
    text-align: right;
}
.compare-table__column--product {
    width: 21%;
    text-align: center;
    vertical-align: top;
    min-width: 180px;
}
.compare-table__column--header, .compare-table__column--product {
    padding: 14px 16px;
}
.compare-table tr:not(:last-child) {
    border-bottom: 1px solid #ebebeb;
}
.compare-table__column--product {
    border-left: 1px solid #ebebeb;
}
/*  end compare */

/* product details */
.product__price{font-size: 24px;
    font-weight: 700;
    color: var(--darkgray);
}
.product__form {
    background: #f9f9f9;
    border-top: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;
}
.subleftmenu{padding:5px 10px; font-size: 14px;}
.caticontick{right:0px; top:0px; background-color: #F5F5F5; color: green;}
.product-general-info .single-general-info {
    border: 2px solid #EBEDF0;
    padding: 10px 15px 10px;
}
.product-general-info .single-general-info:not(:first-child) {
    border-top: 1px !important;
}
.thumbnail-slider-wrap {
    margin-top: 15px;
    height: 85px;
  }
  .thumbnail-slider-wrap .slick-track .slick-slide {
    text-align: center;
  }
  .thumbnail-slider-wrap .slick-track .slick-slide img {
    width: 70%;
  }

.about__image-bg {
    background:url('https://www.hondaindiapower.com/front/media/home-about-parallax.jpg');
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: cover;
    background-position: 50%;
    z-index: -1;
}
.post-header {
    position: relative;
    display: -moz-box;
    display: flex;
    -moz-box-align: center;
    align-items: center;
    z-index: 0;
    overflow: hidden;
}
.post-header--has-image {
    background: #262626;
    margin-bottom: -150px;
    padding-bottom: 150px;
}
.post-header__image {
    position: absolute;
    background: url(https://www.hondaindiapower.com/uploads/banner/29/Press-Release.jpg);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: cover;
    background-position: 50%;
    z-index: -1;
    opacity: .22;
}
.post-header__image-oil {
    background: url(https://www.hondaindiapower.com/uploads/banner/16/honda-genuine-oil-banner.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    opacity: .90;
}
.post-header__image-export {
    background: url(https://honda.wipl.co.in/images/exportHeader.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
    opacity: .90;
}
.post-header__body {
    max-width: 620px;
    margin: 0 auto;
    text-align: center;
    padding: 52px 30px;
}
.post-header--has-image .post-header__body {
    color: #fff;
    padding: 60px 30px;
}
.post-header__title {
    margin: 0;
    font-size: 22px;
    line-height: 28px;
    font-weight: 700;
}
.post-header--has-image .post-header__meta, .post-header--has-image .post-header__title {
    text-shadow: 0 1px 5px rgb(0 0 0 / 25%);
}
.post-header__meta {
    font-size: 14px;
    margin-top: 24px;
    color: #999;
}
.post-header--has-image .post-header__meta {
    color: inherit;
}
.post-header__meta-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: -moz-box;
    display: flex;
    flex-wrap: wrap;
    -moz-box-pack: center;
    justify-content: center;
}
.post-header__meta-item+.post-header__meta-item {
    position: relative;
}
.post-header__meta-item+.post-header__meta-item {
    margin-left: 24px;
}
.decor {
    display: block;
}

.post-header__decor {
    position: absolute;
    bottom: -1px;
}

@media (min-width: 1200px){
    .site--desktop-header--spaceship-one .post-header {
        padding-top: 24px;
    }
}


    @media only screen and (max-width: 991px) {
        .site--desktop-header--spaceship-one .header__search {
            bottom: -45px;  z-index: 2;
        }
        .site--desktop-header--spaceship-one .search__decor {
            bottom: 4px;
        }
        .site--desktop-header--spaceship-one .search__decor-start:before {
            height: 32px;
        }
        .site--desktop-header--spaceship-one .search__decor-end:before {
            height: 32px;
        }
    }
    @media only screen and (max-width: 512px) {
        .site--desktop-header--spaceship-one .header__search {
            width: 300px; bottom: -45px; z-index: 2;
        }
        .site--desktop-header--spaceship-one .search__decor {
            bottom: 4px;
        }
        .site--desktop-header--spaceship-one .search__decor-start:before {
            height: 32px;
        }
        .site--desktop-header--spaceship-one .search__decor-end:before {
            height: 32px;
        }
        .product-card--layout--grid .product-card__footer {
            display: block !important;
        }
    }

